import React, {Component} from 'react';

import YouTube from 'react-youtube';

import {ABOUT_COPY} from "./assets/copy";
import cx from "classnames";
import styles from "../LandingPage/Components/Examples/examples.module.scss";

class AboutPage extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.opts = {
            width: "100%",
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
            }
        };
    }

    render() {
        // For clientAccessToken, see  https://developers.facebook.com/apps/414391302959467/settings/advanced/
        return (
            <div className="page_root">
                <div className="jumbotron text-center">
                    <div className="container">
                        <div className="row pb-3">
                            <div className="col">
                                <h1>
                                    {ABOUT_COPY}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container pt-2">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className={cx("card mb-4", styles.card)}>
                                <YouTube videoId="02_2psrXCnk" opts={this.opts}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutPage;
