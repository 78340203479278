import React, {Component} from 'react';

import cx from 'classnames';
import styles from './fileDragAndDrop.module.scss';

import uploadIcon from './assets/upload_icon.svg';

import {DRAG_DROP_COPY, LOADING_COPY} from './assets/copy'

class FileDragAndDrop extends Component {

	default_state = {
		highlight: false,
		file_names: []
	};

	constructor(props) {
		super(props);
		this.state = this.default_state;
		this.fileInputRef = React.createRef();
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		// If the data has loaded, reset the file drag and drop.
		if (prevProps.loaded !== this.props.loaded && this.props.loaded) {
			this.setState(this.default_state);
		}
	}

	openFileDialog = () => {
		this.fileInputRef.current.click()
	};

	onFilesAdded = (evt) => {
		const files = evt.target.files;
		this.filesToParent(files);
	};

	onDragOver = (evt) => {
		evt.preventDefault();
		this.setState({highlight: true})
	};

	onDragLeave = () => {
		this.setState({highlight: false})
	};

	onDrop = (event) => {
		event.preventDefault();
		const files = event.dataTransfer.files;
		this.filesToParent(files);
		this.setState({highlight: false});
	};

	fileListToArray = (list) => {
		const array = [];
		for (let i = 0; i < list.length; i++) {
			array.push(list.item(i));
		}
		return array;
	};

	filesToParent = (files) => {
		if (this.props.onFilesAdded) {
			const array = this.fileListToArray(files);
			if (array.length > 0) {
				// Getting the file names names
				this.setState({file_names: array.map((e) => e.name).join(', ')});
			}
			this.props.onFilesAdded(array);
		}
	};

	render() {
		return (
			<div
				className={this.state.highlight ?
					cx('flex-grow-1', styles.uploadDropZone, styles.highlight) :
					cx('flex-grow-1', styles.uploadDropZone)}
				onDragOver={this.onDragOver}
				onDragLeave={this.onDragLeave}
				onDrop={this.onDrop}
				onClick={this.openFileDialog}
				style={{cursor: 'pointer'}}>
				<input
					ref={this.fileInputRef}
					className={styles.fileInput}
					type="file"
					multiple
					onChange={this.onFilesAdded}
				>
				</input>
				<div className="row h-100">
					<div className="col align-self-center text-center">
						{this.state.file_names.length > 0 ?
							<div>
								<div className={cx("spinner-border", styles.greenSpinner)} role="status">
									<span className="sr-only"/>
								</div>
								<div className='mt-3'>
									<h4>{LOADING_COPY}</h4>
								</div>
								<div>
									<h5>
										{this.state.file_names}
									</h5>
								</div>
							</div>
							:
							<div>
								<img src={uploadIcon} alt="upload_icon" className={styles.uploadIcon}/>
								<div className="mt-4">
									<h3>
										{DRAG_DROP_COPY}
									</h3>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

export default FileDragAndDrop;

