import React, {Component} from 'react';

import cx from 'classnames';

import styles from './examples.module.scss';

import NATURE_VIDEO from "../../assets/nature_video.mp4";

class Examples extends Component {
	render() {
		return (
			<div className="container pt-2">
				<div className="row justify-content-center">
					<div className="col-lg-4">
						<div className={cx("card p-1 mb-4", styles.card)}>
							<video width="100%" height="100%" src={NATURE_VIDEO} controls autoPlay loop/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Examples;
