import React from 'react';

/* This function essentially creates two components. The first,
Firebase.Context.Provider, is used to provide a firebase instance
once at the top-level of the React component tree. The second,
FirebaseContext.Consumer, is used to retrieve the Firebase instance
if it is needed in the React component.
*/
const FirebaseContext = React.createContext(null);

// Higher order component.
export const withFirebase = Component => props => (
  <FirebaseContext.Consumer>
    {firebase => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export default FirebaseContext;
