import React, {Component} from 'react';

import Upload from "./Components/Upload/upload";
import Examples from "./Components/Examples/examples";

import {LANDING_COPY, UPLOAD_BUTTON, EXAMPLES_BUTTON, DISCONTINUED_COPY} from "./assets/copy";

class LandingPage extends Component {

	constructor(props) {
		super(props);
		this.state = {display_upload: false};
	}

	onButtonClick = () => {
		this.setState({display_upload: !this.state.display_upload});
	};

	render() {
		return (
			<div className="page_root">
				<div className="jumbotron text-center">
					<div className="container">
						<div className="row pb-3">
							<div className="col">
								<h1>
									{LANDING_COPY}
								</h1>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<p>
									Project API <u>discontinued</u>. visit my
									<a href="https://youtube.com/c/garettmacgowan" >&nbsp;YouTube&nbsp;</a>
									for details.
								</p>
								{/*<button className="btn btn-success" onClick={this.onButtonClick}>*/}
								{/*	{this.state.display_upload ?*/}
								{/*		EXAMPLES_BUTTON :*/}
								{/*		UPLOAD_BUTTON*/}
								{/*	}*/}
								{/*</button>*/}
							</div>
						</div>
					</div>
				</div>
				{this.state.display_upload ?
					<Upload/> :
					<Examples/>}
			</div>
		);
	}
}

export default LandingPage;
