import React, {Component} from "react";
import {Link} from 'react-router-dom';

import {navbar_copy} from './assets/copy.js';

import $ from 'jquery';

import * as ROUTES from '../../Constants/routes';

import hamburger_menu from './assets/hamburger_menu.svg';
import instaswipe_navbar_brand from '../../Global_Assets/instaswipe_logo.png';

import './navigation.scss';

class Navigation extends Component {
	constructor(props) {
		super(props);
		this.state = {hide_nav_text: false};
	}

	componentDidMount() {
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	resize = () => {
		this.setState({hide_nav_text: window.innerWidth <= 360});
	};

	closeHam = () => {
		$(".collapse").collapse('hide');
	};

	render() {
		const hide_nav_text = this.state.hide_nav_text;
		return (
			<nav className="navbar navbar-expand-lg nav-background">
				<div className="container">
					<div className="navbar-brand">
						<img src={instaswipe_navbar_brand} alt="Instaswipe" className="instaswipe_navbar_brand"/>
						{hide_nav_text ?
							null
							:
							<h4 className="ml-4">
								<span className="navbar-copy">{navbar_copy}</span>
							</h4>
						}
					</div>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
									aria-controls="navbarNav">
						<img src={hamburger_menu} alt="Navbar Toggle" className="ml-auto"/>
					</button>
					<div className="collapse navbar-collapse ml-auto" id="navbarNav">
						<ul className="navbar-nav ml-auto">
							<Link to={ROUTES.LANDING} className="align-self-end mt-1" onClick={this.closeHam}>
								<h5 className="ml-3 mr-3 navigationLinks">Home</h5>
							</Link>
							<Link to={ROUTES.ABOUT} className="align-self-end mt-1" onClick={this.closeHam}>
								<h5 className="ml-3 mr-3 navigationLinks">About</h5>
							</Link>
						</ul>
					</div>
				</div>
			</nav>
		)
	}
}

export default Navigation;
