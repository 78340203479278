import React, {Component} from 'react';

import { saveAs } from 'file-saver';

import axios from 'axios';

import FileDragAndDrop from "../../../../General/FileDragAndDrop/fileDragAndDrop";

import cx from 'classnames';
import styles from './upload.module.scss';

import {DOWNLOADED_COPY, UNKNOWN_ERROR_COPY, FOUR_ONE_THREE_ERROR_COPY} from './assets/copy';

class Upload extends Component {

	constructor(props) {
		super(props);
		this.state = {
			error_message: '',
			loaded: false,
			downloaded: false
		}
	};

	onError = (error_message) => {
		this.setState({error_message: error_message});
		// Remove the error message after 4 seconds.
		setTimeout(() => {
			this.setState({error_message: ''})
		}, 4000)
	};

	onDownloaded = () => {
		this.setState({downloaded: true});
		// Remove the error message after 3 seconds.
		setTimeout(() => {
			this.setState({downloaded: false})
		}, 3000)
	};

	onFilesAdded = (files) => {
		// Indicate that loading has begun
		this.setState({loaded: false});

		// Set up form data
		const form_data = new FormData();
		files.forEach(file => {
			form_data.append('file', file);
		});

		const config = {
			method: 'post',
			url: 'https://api.instaswipe.ca/insta_swipe',
			responseType: 'blob',
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data: form_data
		};

		const readBlob = (blob, callback) => {
			const reader = new FileReader();
			reader.addEventListener('abort', () => {
				callback('abort', null);
			});
			reader.addEventListener('error', () => {
				callback('error', null);
			});
			reader.addEventListener('loadend', () => {
				try {
					const result = JSON.parse(reader.result);
					callback(null, result)
				} catch {
					callback('error', null)
				}
			});
			reader.readAsText(blob);
		};

		axios(config).then((response) => {
			const resBlob = response.data;
			readBlob(resBlob, (err, resText) => {
				if (err) {
					// If an error occurred in parsing the blob json, the response was likely successful.
					// Save the file
					const file = new File([resBlob], "instaswipe.mp4", {type: "video/mp4"});
					saveAs(file);
					// Trigger downloaded state changes to indicate success to user.
					this.onDownloaded()
				} else {
					if (resText && resText.status === 'error') {
						this.onError(resText.message);
					} else {
						this.onError(UNKNOWN_ERROR_COPY)
					}
				}
				// Set the loaded state to true since request has either succeeded or failed.
				this.setState({loaded: true});
			});
		}).catch((err) => {
			if (err.response && err.response.status === 413) {
				this.onError(FOUR_ONE_THREE_ERROR_COPY)
			} else {
				this.onError(err.message);
			}
			// Set the loaded state to true since the request has failed.
			this.setState({loaded: true});
		});
	};

	render() {
		return (
			<div className="container pt-2">
				<div className="row justify-content-center">
					<div className="col">
						<FileDragAndDrop onFilesAdded={this.onFilesAdded} loaded={this.state.loaded}/>
						{
							this.state.downloaded ?
								<div
									className={cx("d-flex align-items-center justify-content-center text-center mt-3 pt-3 pb-3",
										styles.downloaded)}>
									<h3>{DOWNLOADED_COPY}</h3>
								</div> :
								null
						}
						{
							this.state.error_message.length !== 0 ?
								<div className={cx("d-flex align-items-center justify-content-center text-center mt-3 pt-3 pb-3",
									styles.uploadError)}>
									<h3>{this.state.error_message}</h3>
								</div> :
								null
						}
					</div>
				</div>
			</div>
		)
	}
}

export default Upload;
