import FirebaseContext, { withFirebase } from './context';
import Firebase from './firebase';

/* This file is used to provide a Firebase instance at the top-level
of the component hierarchy.
*/

export default Firebase;

export { FirebaseContext, withFirebase };
