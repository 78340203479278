import app from 'firebase/app';
import 'firebase/analytics';

class Firebase {
	constructor() {
		// Swap out the env file for deployment to production or dev.
		const config = {
			projectId: process.env.REACT_APP_PROJECT_ID,
			apiKey: process.env.REACT_APP_API_KEY,
			authDomain: process.env.REACT_APP_AUTH_DOMAIN,
			databaseURL: process.env.REACT_APP_DATABASE_URL,
			storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
			messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
			appId: process.env.REACT_APP_APP_ID,
			measurementId: process.env.REACT_APP_MEASUREMENT_ID
		};

		app.initializeApp(config);
		this.analytics = app.analytics();
	}
}

export default Firebase;
