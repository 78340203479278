import React, {Component} from "react";
import {BrowserRouter as Router, Route} from 'react-router-dom';

import * as ROUTES from '../../Constants/routes';

import Navigation from '../Navigation/navigation';
import LandingPage from '../AppPages/LandingPage/landingPage';
import AboutPage from "../AppPages/AboutPage/aboutPage";

import './App.scss';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			section_stacking: false
		};
	}

	componentDidMount() {
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	resize = () => {
		// Use this section_stacking props to assist in mobile formatting. UNUSED FOR NOW.
		this.setState({section_stacking: window.innerWidth <= 775});
	};

	render() {
		return (
			<div className="app-wrapper">
				<Router>
					<Navigation/>
					<Route
						exact
						path={ROUTES.LANDING}
						render={props => <LandingPage {...props} section_stacking={this.state.section_stacking}/>}
					/>
					<Route
						exact
						path={ROUTES.ABOUT}
						render={props => <AboutPage {...props} section_stacking={this.state.section_stacking}/>}
					/>
				</Router>
			</div>
		)
	}
}

export default App;
